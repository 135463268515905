import clsx from 'clsx';
import { forwardRef } from 'react';
import { ButtonBase, type ButtonBaseProps, type ButtonRef } from './ButtonBase';

export interface ButtonProps extends ButtonBaseProps {
  variant?: 'default' | 'primary' | 'link' | 'secondary' | 'outline' | 'ghost';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'none';
}

const selectStructureClassNames = (variant: ButtonProps['variant']) => {
  switch (variant) {
    case 'link':
      return 'underline';
    case 'ghost':
      return 'bg-transparent border-none';
    default:
      return 'inline-flex items-center gap-2 rounded-md font-bold border';
  }
};

const selectSizeClassNames = (variant: ButtonProps['variant'], size: ButtonProps['size']) => {
  if (variant === 'link' || variant === 'ghost') {
    switch (size) {
      case 'xs':
        return 'text-2xs';
      case 'sm':
        return 'text-sm';
      case 'lg':
        return 'text-lg';
      default:
        return 'text-base';
    }
  }

  switch (size) {
    case 'none':
      return '';
    case 'xs':
      return 'text-2xs py-[5px] px-2';
    case 'sm':
      return 'text-sm py-[9px] px-4';
    case 'lg':
      return 'text-lg py-[13px] px-6';
    default:
      return 'text-base py-[12px] px-5';
  }
};

const selectVariantClassNames = (variant: ButtonProps['variant']) => {
  switch (variant) {
    case 'primary':
      return 'button--primary text-white bg-primary-800 hover:bg-primary-900 focus:border-primary-800';
    case 'link':
      return 'button--link text-secondary-400 hover:text-secondary-300';
    case 'secondary':
      return 'button--secondary text-white bg-secondary-600 hover:bg-secondary-800 focus:border-secondary-500';
    case 'outline':
      return 'button--outline border-primary-700 text-primary-800 hover:text-primary-800 bg-white hover:bg-primary-50';
    case 'ghost':
      return 'button--ghost hover:bg-transparent focus:!ring-0 focus-visible:!ring-2';
    default:
      return 'button--default border-primary-100 text-primary-700 hover:text-primary-800 bg-primary-50 hover:bg-primary-100';
  }
};

export const Button = forwardRef<ButtonRef, ButtonProps>(({ className, variant = 'default', size, ...props }, ref) => (
  <ButtonBase
    ref={ref}
    className={clsx(
      selectStructureClassNames(variant),
      selectSizeClassNames(variant, size),
      selectVariantClassNames(variant),
      className,
    )}
    {...props}
  />
));
